/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";

import type {
  ReportFormEffectRequest,
  ReportFormResponse,
  ReportHistoryEffectRequest,
  ReportListResponse,
  ReportHistoryResponse,
} from "state/reports";

import {
  REPORTFORM_REQUEST,
  REPORTFORM_RESPONSE,
  REPORT_LIST_REQUEST,
  REPORT_LIST_RESPONSE,
  REPORTHISTORY_REQUEST,
  REPORTHISTORY_RESPONSE,
} from "state/reports";
import { getReportForm, getReportHistory, reportList } from "queries";

export const PAGE_SIZE = 32;

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async (msg: ReportFormEffectRequest) => {
      try {
        const data = await client(getReportForm,
          {
            district: msg.district,
          },
          { cache: true });
        return ({
          tag: REPORTFORM_RESPONSE,
          data: data && data.getReportForm,
        }: ReportFormResponse);
      }
      catch (e) {
        return ({
          tag: REPORTFORM_RESPONSE,
          data: null,
          error: "ERROR",
        });
      }
    },
    subscribe: { [REPORTFORM_REQUEST]: true },
  });

  storage.addEffect({
    effect: async () => {
      try {
        const data = await client(reportList,
          { cache: true });
        return ({
          tag: REPORT_LIST_RESPONSE,
          data: data && data.reportList,
        }: ReportListResponse);
      }
      catch (e) {
        return ({
          tag: REPORT_LIST_RESPONSE,
          data: null,
          error: "ERROR",
        });
      }
    },
    subscribe: { [REPORT_LIST_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: ReportHistoryEffectRequest) => {
      try {
        const data = await client(getReportHistory,
          {
            id: msg.id,
            pageNumber: msg.pageNumber,
            pageLimit: msg.pageLimit,
          },
          { cache: true });
        return ({
          tag: REPORTHISTORY_RESPONSE,
          data: data && data.getReportHistory,
        }: ReportHistoryResponse);
      }
      catch (e) {
        return ({
          tag: REPORTHISTORY_RESPONSE,
          data: null,
          error: "ERROR",
        });
      }
    },
    subscribe: { [REPORTHISTORY_REQUEST]: true },
  });
};

export default registerClient;
