/* @flow */

import React from "react";
import styles from "./styles.scss";
import cn from "classnames";
import { PAGE_SIZE } from "effects/reports";

type TableLargeProps = {
  headers: Array<string>,
  table: Array<Array<string>>,
};

const TableLargeHead = ({ headers }: any) => {
  return (
    <thead>
      <tr>
        {headers.map(header => (
          <th key={header}>{header}</th>
        ))}
      </tr>
    </thead>
  );
};

const TableLarge = ({ headers, table }: TableLargeProps) => {
  return (
    <div className={styles.tableLarge}>
      <table className={styles.table}>
        <TableLargeHead headers={headers} />
        <tbody>
          {table.map((row, i) => (
            <tr key={`${row[i]}-${i}`}>
              {row.map((item, i) => (
                <td key={`${item}-${i}`}>{item}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className={styles.spacer} />
    </div>
  );
};

export const HistoryTableLargeDummy = () => (
  <div className={cn(styles.tableLarge, styles.tableLargeDummy, styles.tableDummy)}>
    <table className={cn(styles.table, styles.tableDummy)}>
      <thead>
        <tr>
          {Array.from({ length: 5 }).map((_, i) => (
            <th key={i}><span>&nbsp;</span></th>
          ))}
        </tr>
      </thead>
      <tbody>
        {Array.from({ length: PAGE_SIZE }).map((_, i) => (
          <tr key={`tr-${i}`}>
            {Array.from({ length: 5 }).map((_, i) => (
              <td key={`td-${i}`}><span>&nbsp;</span></td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default TableLarge;
