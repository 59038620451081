/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { QuoteRequest, QuoteResponse } from "state/quote";
import type { Quote } from "shop-state/types";

import {
  QUOTE_INIT_REQUEST,
  QUOTE_INIT_RESPONSE,
  QUOTE_ADD_ITEM_REQUEST,
  QUOTE_ADD_ITEM_RESPONSE,
  QUOTE_REMOVE_ITEM_REQUEST,
  QUOTE_REMOVE_ITEM_RESPONSE,
  QUOTE_UPDATE_ITEM_REQUEST,
  QUOTE_UPDATE_ITEM_RESPONSE,
  QUOTE_SET_ADDRESSES_REQUEST,
  QUOTE_SET_ADDRESSES_RESPONSE,
  QUOTE_LOAD_REQUEST,
  QUOTE_LOAD_RESPONSE,
  QUOTE_SET_PAYMENT_METHOD_STRIPE_REQUEST,
  QUOTE_SET_PAYMENT_METHOD_STRIPE_RESPONSE,
} from "@crossroads/shop-state/quote";
import {
  QUOTE_SET_POINTS_PAYMENT_REQUEST,
  QUOTE_SET_POINTS_PAYMENT_RESPONSE,
} from "state/quote";
import { addMessage } from "state/messages";
import {
  quote,
  addToCart,
  removeQuoteItem,
  updateQuoteItemQty,
  setQuoteShippingMethodToCheapest,
  setQuoteBillingAddress,
  setQuoteBillingAddressAsShippingAddress,
  updateCustomerEmail,
  updateCustomerAddress,
  customer as customerQuery,
  setQuotePaymentMethodStripe,
  removeUnorderableQuoteItems,
  quotePointsSet,
  quotePointsSetToMaximum,
} from "queries";

const removeExampleEmail = (object?: { email: ?string }): void => {
  if (object && object.email && object.email.match(/@example.com$/)) {
    object.email = null;
  }
};

const removeQuoteExampleEmail = <T: { quote: Quote }>(response: T): T => {
  if (response.quote) {
    removeExampleEmail(response.quote);
  }

  return response;
};

const registerClient = (
  storage: Storage,
  client: Client<{}>) => {
  storage.addEffect({
    effect: (msg: QuoteRequest, path) => {
      return client(quote)
        .then(removeQuoteExampleEmail)
        .then(data => {
          storage.replyMessage(({
            tag: QUOTE_INIT_RESPONSE,
            data: data && data.quote,
          }: QuoteResponse), path);
        });
    },
    subscribe: { [QUOTE_INIT_REQUEST]: true },
  });

  storage.addEffect({
    effect: (_, path) => {
      return client(removeUnorderableQuoteItems)
        .then(async ({ removeUnorderableQuoteItems: removedItems }) => {
          for (const item of removedItems) {
            storage.broadcastMessage(addMessage({
              translationKey: "ITEM_REMOVED_FROM_CART",
              variable: {
                name: item.product.name,
              },
            }, "success"));
          }

          const data = await client(quote);

          storage.replyMessage(({
            tag: QUOTE_LOAD_RESPONSE,
            data: data && data.quote,
          }: QuoteResponse), path);
        });
    },
    subscribe: { [QUOTE_LOAD_REQUEST]: true },
  });

  storage.addEffect({
    effect: (msg: QuoteRequest, path) => {
      if (msg.tag === QUOTE_ADD_ITEM_REQUEST) {
        const getParams = msg => {
          if (msg.bundleOptions) {
            return {
              buyRequest: msg.buyRequest,
              qty: msg.qty,
              bundleOptions: msg.bundleOptions,
            };
          }

          if (msg.customOptions !== null) {
            return {
              buyRequest: msg.buyRequest,
              qty: msg.qty,
              customOptions: msg.customOptions,
            };
          }

          return {
            buyRequest: msg.buyRequest,
            qty: msg.qty,
          };
        };

        const params = getParams(msg);

        client(addToCart, params)
          .then(data => {
            if (data && data.addQuoteItem.result !== "success") {
              storage.broadcastMessage(addMessage(data.addQuoteItem.result, "error"));
            }
          });

        client(setQuoteShippingMethodToCheapest);

        return client(quote)
          .then(removeQuoteExampleEmail)
          .then(data => {
            storage.replyMessage(({
              tag: QUOTE_ADD_ITEM_RESPONSE,
              data: data && data.quote,
            }: QuoteResponse), path);
          });
      }
    },
    subscribe: { [QUOTE_ADD_ITEM_REQUEST]: true },
  });

  storage.addEffect({
    effect: (msg: QuoteRequest, path) => {
      if (msg.tag === QUOTE_UPDATE_ITEM_REQUEST) {
        client(updateQuoteItemQty, { itemBuyRequest: msg.itemBuyRequest, qty: msg.qty })
          .then(data => {
            if (data && data.updateQuoteItemQty.result !== "success") {
              storage.broadcastMessage(addMessage(data.updateQuoteItemQty.result, "error"));
            }
          });

        client(setQuoteShippingMethodToCheapest);

        return client(quote)
          .then(removeQuoteExampleEmail)
          .then(data => {
            storage.replyMessage(({
              tag: QUOTE_UPDATE_ITEM_RESPONSE,
              data: data && data.quote,
            }: QuoteResponse), path);
          });
      }
    },
    subscribe: { [QUOTE_UPDATE_ITEM_REQUEST]: true },
  });

  storage.addEffect({
    effect: (msg: QuoteRequest, path) => {
      if (msg.tag === QUOTE_REMOVE_ITEM_REQUEST) {
        client(removeQuoteItem, { itemBuyRequest: msg.itemBuyRequest });
        client(setQuoteShippingMethodToCheapest);

        return client(quote)
          .then(removeQuoteExampleEmail)
          .then(data => {
            storage.replyMessage(({
              tag: QUOTE_REMOVE_ITEM_RESPONSE,
              data: data && data.quote,
            }: QuoteResponse), path);
          });
      }
    },
    subscribe: { [QUOTE_REMOVE_ITEM_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: QuoteRequest, path) => {
      if (msg.tag === QUOTE_SET_ADDRESSES_REQUEST) {
        const { customer } = await client(customerQuery);
        const addressesLackingPhone = customer.addresses.filter(e => e.telephone === null);

        // Add telephone number to customer address if missing
        if (addressesLackingPhone.length > 0) {
          addressesLackingPhone.forEach(async el => {
            const addressObj = {
              firstname: el.firstname,
              lastname: el.lastname,
              company: el.company,
              city: el.city,
              postcode: el.postcode,
              street: el.street,
              awarditOrgNr: el.awarditOrgNr,
              telephone: msg.billing.telephone,
              countryCode: msg.billing.countryCode,
            };
            await client(updateCustomerAddress, { id: (el.id: number), address: addressObj });
          });
        }

        const q1 = client(setQuoteBillingAddress, { address: msg.billing });
        const q2 = client(setQuoteBillingAddressAsShippingAddress);
        const q3 = client(updateCustomerEmail, { email: msg.email });
        const q4 = client(setQuoteShippingMethodToCheapest);

        const errors = (await Promise.all([q1, q2, q3, q4])).reduce((acc, curr) => {
          for (const key of Object.keys(curr)) {
            if (key === "setQuoteShippingMethodToCheapest") {
              continue;
            }

            // $FlowIgnore
            const { result } = curr[key];

            if (key === "updateCustomerEmail" && result === "errorDisabled") {
              continue;
            }

            if (typeof result === "string" && result.includes("error")) {
              acc[key] = result;
              storage.broadcastMessage(addMessage(`${key}_${result}`, "error"));
            }
          }

          return acc;
        }, {});

        return client(quote)
          .then(removeQuoteExampleEmail)
          .then(data => {
            storage.replyMessage(({
              tag: QUOTE_SET_ADDRESSES_RESPONSE,
              data: data && data.quote,
              errors: Object.keys(errors).length > 0 ? errors : undefined,
            }: QuoteResponse), path);
          });
      }
    },
    subscribe: { [QUOTE_SET_ADDRESSES_REQUEST]: true },
  });

  storage.addEffect({
    effect: async () => {
      await client(setQuotePaymentMethodStripe);

      const { quote: data } = await client(quote);

      return ({
        tag: QUOTE_SET_PAYMENT_METHOD_STRIPE_RESPONSE,
        data,
      }: QuoteResponse);
    },
    subscribe: { [QUOTE_SET_PAYMENT_METHOD_STRIPE_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: QuoteRequest) => {
      if (msg.tag === QUOTE_SET_POINTS_PAYMENT_REQUEST) {
        const params = {
          id: msg.id,
          points: msg.points,
        };

        let quote;

        // TODO: check result for error on setQuotePointsPayment.result
        if (msg.points >= 0) {
          const result = await client(quotePointsSet, params);
          quote = result.quotePointsSet.quote;
        }
        else {
          const result = await client(quotePointsSetToMaximum, params);
          quote = result.quotePointsSetToMaximum.quote;
        }

        return ({
          tag: QUOTE_SET_POINTS_PAYMENT_RESPONSE,
          data: quote,
        }: QuoteResponse);
      }
    },
    subscribe: { [QUOTE_SET_POINTS_PAYMENT_REQUEST]: true },
  });
};

export default registerClient;
