/* @flow */

import type { TransactionFlag } from "shop-state/types";
import type { DateFrom } from "components/AccountView/TransactionsView/Controls";

import React, { useState, useEffect, useContext, useCallback } from "react";
import { Helmet } from "react-helmet-async";
import { useData, useSendMessage } from "crustate/react";
import { useTranslate } from "@awardit/react-use-translate";
import { TransactionsData } from "data";
import { StoreInfoContext } from "entrypoint/shared";
import { loadTransactions } from "state/transactions";
import Wrapper from "components/Wrapper";
import Back from "components/AccountView/Back";
import Button from "components/Button";
import Header from "components/AccountView/Header";
import TableLarge, { TableLargeHint } from "components/AccountView/TransactionsView/TableLarge";
import TableSmall, { TableSmallHint } from "components/AccountView/TransactionsView/TableSmall";
import Receipt from "components/AccountView/TransactionsView/Receipt";
import Controls, { FILTER_ALL } from "components/AccountView/TransactionsView/Controls";

import styles from "./styles.scss";

const TransactionsView = () => {
  const t = useTranslate();
  const { info, routes, content: { transactionsview } } = useContext(StoreInfoContext);
  const sendMessage = useSendMessage();
  const transaction = useData(TransactionsData);
  const [current, setCurrent] = useState<string | null>(null);
  const [currentReceipt, setCurrentReceipt] = useState<string | null>(null);
  const [activeFilter, setActiveFilter] = useState<TransactionFlag | "ALL">(FILTER_ALL);
  const [searchValue, setSearchValue] = useState("");
  const [dateFrom, setDateFrom] = useState<?DateFrom>();

  const load = useCallback(() => {
    sendMessage(loadTransactions({
      filter: activeFilter === "ALL" ? (transactionsview.allTransactions === true ? { transactionFlag: "ALL_INCLUDING_CANCELED_AND_REFUND" } : null) : { transactionFlag: activeFilter },
      size: 1000,
      from: dateFrom?.date,
      lang: info.locale.slice(0, 2),
    }));
  }, [sendMessage, loadTransactions, activeFilter, dateFrom]);

  useEffect(() => {
    load();
  }, [activeFilter, dateFrom]);

  const filteredTransactions = transaction.state !== "LOADED" ? [] :
    transaction.data.filter(x =>
      searchValue.length > 0 ?
        (x.description && x.description.toLowerCase().includes(searchValue.toLowerCase())) ||
        (x.id && x.id.includes(searchValue)) :
        true);

  return (
    <Wrapper variant="pageWrapper" className="awardit-pageWrapper">
      <Helmet
        title={transactionsview.heading}
      />
      <Back />
      <Header subHeader={transactionsview.subHeading ?? ""}>
        {routes.accountingRecordsView &&
        routes.accountingRecordsView.toggle !== undefined &&
        routes.accountingRecordsView.toggle &&
        routes.accountingRecordsView.url !== undefined &&
        typeof routes.accountingRecordsView.url === "string" &&
        routes.accountingRecordsView.url.length > 0 &&
        routes.accountingRecordsView.title !== undefined &&
        <Button to={routes.accountingRecordsView.url} size="small" variant="primary" className={styles.recordsButton}>
          {routes.accountingRecordsView.title}
        </Button>
        }
      </Header>
      <Receipt
        currentReceipt={currentReceipt}
        setCurrentReceipt={setCurrentReceipt}
      />

      <Controls
        disabled={transaction.state === "LOADING" || transaction.state === "UPDATING"}
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        dateFrom={dateFrom}
        setDateFrom={setDateFrom} />

      {transaction.state === "ERROR" &&
        <div className={styles.errorMessage}>
          <h2>{t("ERRORVIEW.TITLE")}</h2>
          <Button variant="primary" onClick={load}>{t("TRY_AGAIN")}</Button>
        </div>
      }

      {(transaction.state === "UPDATING" || transaction.state === "LOADING") &&
        <>
          <TableSmallHint />
          <TableLargeHint />
        </>
      }

      {transaction.state === "LOADED" &&
        <>
          <TableSmall
            items={filteredTransactions}
            current={current}
            setCurrentReceipt={setCurrentReceipt}
            onClick={x => setCurrent(current !== x.id ? x.id : null)}
          />
          <TableLarge
            setCurrentReceipt={setCurrentReceipt}
            items={filteredTransactions}
          />
        </>
      }
    </Wrapper>
  );
};

export default TransactionsView;
