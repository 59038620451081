/* @flow */

import { useContext, useMemo } from "react";
import { useLocation } from "react-router";
import { StoreInfoContext } from "entrypoint/shared";

const useOnFullMenuHiddenRoute = () => {
  const { routes, configuration } = useContext(StoreInfoContext);
  const { pathname } = useLocation();
  const aboutSveaUrl = "/about-svea";
  const aboutReleaseUrl = "/about-release";
  const aboutWownyckelnUrl = "/about-wownyckeln";
  const aboutDreamteamUrl = "/about-dreamteam";
  const privacyNoticeUrl = "/privacy-notice";
  const formsPath = "/forms";
  const earnView = ((routes.earnViewSubpage && routes.earnViewSubpage.toggle) ||
  (routes.earnViewSubpage2 && routes.earnViewSubpage2.toggle)) && true;

  const shopTermsUrl = (configuration.customShopTermsUrl &&
    configuration.hasCustomShopTermsUrl === true) ?
    configuration.customShopTermsUrl : "checkout-terms";

  // For som reason npm-test gets an timeout error in the pipeline
  // when termsView was added like the other routes. When it was
  // moved outside of FULL_MENU_HIDDEN_ROUTES it was working again.
  // Caramel has the most routes in FULL_MENU_HIDDEN_ROUTES
  // compared to the other themes.
  const termsView: Array<string | null> = routes.termsView && routes.termsView.url ?
    [routes.termsView.url] : [];

  const FULL_MENU_HIDDEN_ROUTES = useMemo(() => ([
    ...routes.accountView && routes.accountView.url ? [routes.accountView.url] : [],
    ...routes.offerListView && routes.offerListView.url ? [routes.offerListView.url] : [],
    ...routes.newsListView && routes.newsListView.url ? [routes.newsListView.url] : [],
    ...routes.suppliersView && routes.suppliersView.url ? [routes.suppliersView.url] : [],
    ...routes.quizList && routes.quizList.url ? [routes.quizList.url] : [],
    ...routes.quizHistory && routes.quizHistory.url ? [routes.quizHistory.url] : [],
    ...termsView,
    ...routes.currentInfoView1 && routes.currentInfoView1.url ? [routes.currentInfoView1.url] : [],
    ...routes.currentInfoView2 && routes.currentInfoView2.url ? [routes.currentInfoView2.url] : [],
    ...routes.currentInfoView3 && routes.currentInfoView3.url ? [routes.currentInfoView3.url] : [],
    ...routes.currentInfoView4 && routes.currentInfoView4.url ? [routes.currentInfoView4.url] : [],
    ...routes.currentInfoView5 && routes.currentInfoView5.url ? [routes.currentInfoView5.url] : [],
    ...routes.currentInfoView6 && routes.currentInfoView6.url ? [routes.currentInfoView6.url] : [],
    ...routes.alternateContent1 && routes.alternateContent1.url ?
      [routes.alternateContent1.url] : [],
    ...routes.alternateContent2 && routes.alternateContent2.url ?
      [routes.alternateContent2.url] : [],
    ...routes.alternateContent3 && routes.alternateContent3.url ?
      [routes.alternateContent3.url] : [],
    ...routes.reportsView && routes.reportsView.url ? [routes.reportsView.url] : [],
    ...routes.calculatorView && routes.calculatorView.url ? [routes.calculatorView.url] : [],
    ...routes.photoServiceView && routes.photoServiceView.url ? [routes.photoServiceView.url] : [],
    aboutSveaUrl,
    aboutReleaseUrl,
    aboutWownyckelnUrl,
    aboutDreamteamUrl,
    privacyNoticeUrl,
    shopTermsUrl,
    formsPath,
    ...routes.leadsView && routes.leadsView.url ? [routes.leadsView.url] : [],
    ...routes.formListView && routes.formListView.url ? [routes.formListView.url] : [],
    ...routes.faqView && routes.faqView.url ? [routes.faqView.url] : [],
    ...routes.leaderboardView &&
    routes.leaderboardView.url ? [routes.leaderboardView.url] : [],
    earnView === false ? routes.earnView?.url : undefined,
  ]), [routes]);

  return FULL_MENU_HIDDEN_ROUTES.some(route => pathname.includes(route));
};

export default useOnFullMenuHiddenRoute;
